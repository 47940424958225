<template>
  <div>
    <div class="downloadbox">
      <div class="container">
        <header class="clearfix">
          <div class="logo-wrap">
            <h1><a href="javascript:void(0);" title="rockminer"></a></h1>
          </div>
          <a href="javascript: void(0);" class="wtchat-server-btn">
            <i class="icon icon-wechat w-24"></i>
            微信公众号
            <div class="pop">
              <img src="@/assets/picture/download/qr-img.jpg" alt="" />
            </div>
          </a>
        </header>
        <main>
          <div class="main-phone"></div>
          <div class="main-content">
            <h2 class="title">两江上游云</h2>
            <p>重庆市级技术平台</p>
            <p>整合新闻媒体资源，推动传统媒体和新兴媒体融合发展。</p>
            <div class="qr-code clearfix">
              <div class="img">
                <img src="@/assets/picture/download/code.png" alt="" />
              </div>
              <div class="info">
                <p>扫码下载APP</p>
                <ul>
                  <!-- <li>
                             <i class="icon icon-ios w-25"></i>
                             iOS
                         </li> -->
                  <!-- <li class="line"></li> -->
                  <li>
                    <i class="icon icon-android w-25"></i>
                    Android
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </main>
      </div>
      <footer>
        <div class="container">
          <div id="copyright">
            重庆广大融媒科技有限公司 ©2021
            <a href="javascript:;" target="_blank"
              >备案号：渝ICP备2021004499号</a
            >
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";

import "@/assets/css/download.normalize.css";
import "@/assets/css/download.main.css";

export default {
  name: "Download",
  setup() {
    onMounted(() => {
    console.log('打开了手机页面');
      let viewSize = document.documentElement.clientWidth;
      if (viewSize < 767) {
        window.location.href = "./downloadAppPhone";
      }
    });
  },
};
</script>

<style scoped>
/* @import url("@/assets/css/download.normalize.css"); */
/* @import url("@/assets/css/download.main.css"); */
.downloadbox {
    display: block;
  max-width: 1920px;
  margin: 0 auto;
}
</style>